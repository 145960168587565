import { Grid } from '@mui/material'
import React from 'react'
import styles from './buttonstyle.css'
export default function Buttons(props) {
  return (  <>
    <Grid container sx={{p:35}}>
  <button class="buttonnew add-to-cart" onClick={()=>props.propFunc('member')}>
    <i class="fa fa-cart-plus"></i>Create New Member
  </button>
  <br />
  <button class="buttonnew red" onClick={()=>props.propFunc('profit')}>
    <i class="fa fa-trash"></i>Create Profit
  </button>
  <br />
  <button class="buttonnew green" onClick={()=>props.propFunc('transaction')}>
    <i class="fa fa-thumbs-up"></i>Create Transaction
  </button>
</Grid>
</>
  )
  
}
