import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Alert, Button, Grid, Snackbar, TextField } from '@mui/material';
import axios from 'axios';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function UpdateProduct(props) {
    const [value, setValue] = React.useState(0);
    const [disableSGS, setdisableSGS] = useState(true)
    const [disableTGD, setdisableTGD] = useState(true)
    const [createError, setError] = useState(false);
    const [errorMessaage, setErrorMessage] = useState(null);
    const [productData, setUserPersonalData] = useState(props.productData)
    const [SGSData, setSGSData] = useState({ UST: 0, USDC: 0, DAI: 0, USDT: 0 })
    const [remainingValue, setRemainingValue] = useState(100)
    const [TGDData, setTGDData] = useState({ One: 0, FTM: 0, SOL: 0, AVAX: 0,CRO: 0, ETH: 0, BTC: 0, other: 0 })
    const [remainingTGDValue, setRemainingTGDValue] = useState(100)
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setdisableSGS(true)
        setdisableTGD(true)
    };

    useEffect(() => {
        getUpdatedProducts()
        // setUserPersonalData(props.productData)
    }, [])

    const submitSGS = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const newData = {
            productName: 'SGS',
            productId: props.productData[0]?.id,
            customerId: props.userId,
            yieldData: parseFloat(data.get('sgs-yield')),
            allocation: parseFloat(data.get('sgs-allocation')),
            assetBreakDownPrevious: props.productData[0]?.assetBreakDown,
            assetBreakDownNew: [
                { name: "USDC", amount: parseFloat(data.get('USDC')) },
                { name: "DAI", amount: parseFloat(data.get('DAI')) },
                { name: "USDT", amount: parseFloat(data.get('USDT')) },
                { name: "UST", amount: parseFloat(data.get('UST')) }]
        }
        let SGSTotal = 0;
        SGSTotal = SGSData.DAI + SGSData.USDC + SGSData.USDT + SGSData.UST;
        if (newData !== undefined || newData !== null&&SGSTotal<=100) {
            updateproducts(newData)
        }
    }

    const submitTGD = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const newData = {
            productName: "The Great Dynamic",
            productId: props.productData[1].id,
            customerId: props.userId,
            yieldData: parseFloat(data.get('tgd-yield')),
            allocation: parseFloat(data.get('tgd-allocation')),
            assetBreakDownPrevious: props.productData[1].assetBreakDown,
            assetBreakDownNew: [
                { name: "One", amount: parseFloat(data.get('One')) },
                { name: "FTM", amount: parseFloat(data.get('FTM')) },
                { name: "SOL", amount: parseFloat(data.get('SOL')) },
                { name: "AVAX", amount: parseFloat(data.get('AVAX')) },
                { name: "CRO", amount: parseFloat(data.get('CRO')) },
                { name: "ETH", amount: parseFloat(data.get('ETH')) },
                { name: "BTC", amount: parseFloat(data.get('BTC')) },
                { name: "other", amount: parseFloat(data.get('other')) }

            ]
        };
        let TGDTotal = 0;
        TGDTotal = TGDData.One + TGDData.FTM + TGDData.SOL + TGDData.AVAX+ TGDData.CRO + TGDData.ETH + TGDData.BTC + TGDData.other;
        if (newData !== undefined || newData !== null&&TGDTotal<=100) {
            updateproducts(newData)
        }
    }

    const updateproducts = (newData) => {
        let token = localStorage.getItem('userToken');
        const confiq = {
            headers: {
                Authorization: `Bearer ${token} `,
            },
        };
        axios
            .post(
                `${process.env.REACT_APP_API_URL}customers/updateproducts`, newData, confiq
            )
            .then(function (response) {
                if (response.data.assetUpdate[0].changedRows > 0 || response.data.productUpdate.changedRows > 0) {
                    setError(true);
                    setErrorMessage('Updated Successfully');
                    getUpdatedProducts()
                } else {
                    setError(true);
                    setErrorMessage('Already UptoDate!');
                }
            })
            .catch(function (error) {
                console.log(error);
                setError(true);
                setErrorMessage('Something Went Wrong');
            });
    }
    const getUpdatedProducts = () => {
        let token = localStorage.getItem('userToken');
        const confiq = {
            headers: {
                Authorization: `Bearer ${token} `,
            },
        };
        const bodyParameters = {
            key: 'value',
        };
        axios
            .post(
                `${process.env.REACT_APP_API_URL}customers/userTransaction?id=${props.userId}&type=product_update`,
                bodyParameters,
                confiq
            )
            .then(function (response) {
                if (response.data.length > 0) {
                    setUserPersonalData(response.data);
                }
            })
            .catch(function (error) {
                setError(true);
                setErrorMessage('Something Went Wrong');
            });
    }
    const handleValues = (e) => {
        let SGSTotal = 0;


        for (const [key, value] of Object.entries(SGSData)) {
            if (key !== e.target.name) {
                SGSTotal += SGSData[key]
            }
        }
        SGSTotal += parseInt(e.target.value);
        if (SGSTotal <= 100) {
            setSGSData({ ...SGSData, [e.target.name]: parseFloat(e.target.value) })
        } else {
            setRemainingValue('Allocation Reached.')
        }
    }
    const disableSGSFunc = () => {
        setdisableSGS(true)
        setSGSData({ UST: 0, USDC: 0, DAI: 0, USDT: 0 })
        setRemainingValue(100)
    }
    useEffect(() => {
        let SGSTotal = 0;
        SGSTotal = SGSData.DAI + SGSData.USDC + SGSData.USDT + SGSData.UST;
        setRemainingValue(100 - SGSTotal)
    }, [SGSData])

    const handleTGDValues = (e) => {
        let TGDTotal = 0;
        for (const [key, value] of Object.entries(TGDData)) {
            if (key !== e.target.name) {
                TGDTotal += TGDData[key]
            }
        }
        console.log(TGDTotal);
        TGDTotal += parseInt(e.target.value);
        if (TGDTotal <= 100) {
            setTGDData({ ...TGDData, [e.target.name]: parseFloat(e.target.value) })
        } else {
            setRemainingTGDValue('Allocation Reached.')
        }
    }
    const disableTGDFunc = () => {
        setdisableTGD(true)
        setTGDData({  One: 0, FTM: 0, SOL: 0, AVAX: 0,CRO: 0, ETH: 0, BTC: 0, other: 0  })
        setRemainingTGDValue(100)
    }
    useEffect(() => {
        let TGDTotal = 0;
        TGDTotal = TGDData.One + TGDData.FTM + TGDData.SOL + TGDData.AVAX+ TGDData.CRO + TGDData.ETH + TGDData.BTC + TGDData.other;
        setRemainingTGDValue(100 - TGDTotal)
    }, [TGDData])

    return (
        <>
            <Snackbar
                open={createError}
                autoHideDuration={6000}
                onClose={() => setError(false)}
                message={errorMessaage}
            >
                <Alert
                    onClose={() => setError(false)}
                    severity={
                        errorMessaage === 'Updated Successfully' ? 'success' : 'error'
                    }
                    sx={{ width: '100%' }}
                >
                    {errorMessaage}
                </Alert>
            </Snackbar>
            <h3>Products</h3>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {productData?.map((data) => (
                            <Tab label={data.product_name} {...a11yProps(0)} />))}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}
                >
                    <Grid container style={{ border: '1px solid grey' }}
                        component='form'
                        name='createForm'
                        noValidate
                        onSubmit={submitSGS}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ p: 1 }}
                            justifyItems='center'
                        >
                            <Typography variant='p'>Stable Growth Staking</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
                            <label>Yield</label>
                            {disableSGS ? <TextField
                                value={productData[0]?.yield}
                                margin='normal'
                                required
                                fullWidth
                                id='sgs-yield'
                                label=''
                                name='sgs-yield'
                                autoComplete='off'
                                disabled={disableSGS}
                            /> : <TextField
                                type='number'
                                margin='normal'
                                required
                                fullWidth
                                id='sgs-yield'
                                label=''
                                name='sgs-yield'
                                autoComplete='off'
                                disabled={disableSGS}
                                inputProps={{
                                    max: 100,
                                    min: 0
                                }}

                            />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
                            <label>Allocation</label>
                            {disableSGS ? <TextField
                                value={productData[0]?.allocation}
                                margin='normal'
                                required
                                fullWidth
                                id='sgs-allocation'
                                label=''
                                name='sgs-allocation'
                                autoComplete='off'
                                disabled={disableSGS}
                            /> : <TextField
                                type='number'
                                inputProps={{
                                    max: 100,
                                    min: 0
                                }}
                                margin='normal'
                                required
                                fullWidth
                                id='sgs-allocation'
                                label=''
                                name='sgs-allocation'
                                autoComplete='off'
                                disabled={disableSGS}
                            />}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ p: 1 }}
                            justifyItems='center'
                        >
                            <Typography variant='p'>Asset Breakdown</Typography>
                        </Grid>
                        {productData[0]?.assetBreakDown.map((data) => (
                            disableSGS ? <Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
                                <label>{data.name}</label>
                                <TextField
                                    value={data.amount}
                                    margin='normal'
                                    required
                                    fullWidth
                                    id={data.name}
                                    label=''
                                    name={data.name}
                                    autoComplete='off'
                                    disabled={disableSGS}
                                />
                            </Grid> : <Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}><label>{data.name}</label>
                                <TextField
                                    type='number'
                                    inputProps={{
                                        max: 100,
                                        min: 0
                                    }}
                                    onChange={(e) => handleValues(e)}
                                    margin='normal'
                                    required
                                    fullWidth
                                    id={data.name}
                                    label=''
                                    name={data.name}
                                    autoComplete='off'
                                    disabled={disableSGS}
                                /><Typography variant='p'>{`Remaining Asset : ${remainingValue}`}</Typography> </Grid>))}
                        <Grid item xs={6}></Grid>
                        <Grid item xs={disableSGS ? 6 : 3}>
                            {disableSGS ? <Button variant='contained' onClick={() => setdisableSGS(false)}>Update</Button> :
                                <><Button variant='outlined' onClick={() => disableSGSFunc()}>Cancel</Button>
                                    &nbsp;<Button variant='contained' type='submit' disabled={remainingValue<=100?false:true}>Save</Button></>}
                        </Grid>
                        <Grid item xs={6} sx={{ mt: 2 }}></Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}
                >
                    <Grid container style={{ border: '1px solid grey' }}
                        component='form'
                        name='createForm'
                        noValidate
                        onSubmit={submitTGD}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ p: 1 }}
                            justifyItems='center'
                        >
                            <Typography variant='p'>The Great Dynamic</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
                            <label>Yield</label>
                            {disableTGD ? <TextField
                                value={productData[1]?.yield}
                                margin='normal'
                                required
                                fullWidth
                                id='tgd-yield'
                                label=''
                                name='tgd-yield'
                                autoComplete='off'
                                disabled={disableTGD}
                            /> : <TextField

                                type='number'
                                inputProps={{
                                    max: 100,
                                    min: 0
                                }}
                                margin='normal'
                                required
                                fullWidth
                                id='tgd-yield'
                                label=''
                                name='tgd-yield'
                                autoComplete='off'
                                disabled={disableTGD}
                            />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ p: 1 }}>
                            <label>Allocation</label>
                            {disableTGD ? <TextField
                                value={productData[1]?.allocation}
                                margin='normal'
                                required
                                fullWidth
                                id='tgd-allocation'
                                label=''
                                name='tgd-allocation'
                                autoComplete='off'
                                disabled={disableTGD}
                            /> : <TextField
                                type='number'
                                inputProps={{
                                    max: 100,
                                    min: 0
                                }}
                                margin='normal'
                                required
                                fullWidth
                                id='tgd-allocation'
                                label=''
                                name='tgd-allocation'
                                autoComplete='off'
                                disabled={disableTGD}
                            />}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ p: 1 }}
                            justifyItems='center'
                        >
                            <Typography variant='p'>Asset Breakdown</Typography>
                        </Grid>
                        {productData[1]?.assetBreakDown?.map((data) => (
                            disableTGD ? <Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}>
                                <label>{data.name}</label>
                                <TextField
                                    value={data.amount}
                                    margin='normal'
                                    required
                                    fullWidth
                                    id={data.name}
                                    label=''
                                    name={data.name}
                                    autoComplete='off'
                                    disabled={disableTGD}
                                />
                            </Grid> : <Grid item xs={12} sm={12} md={12} lg={3} sx={{ p: 1 }}><label>{data.name}</label>
                                <TextField
                                    type='number'
                                    inputProps={{
                                        max: 100,
                                        min: 0
                                    }}
                                    onChange={(e)=>handleTGDValues(e)}
                                    margin='normal'
                                    required
                                    fullWidth
                                    id={data.name}
                                    label=''
                                    name={data.name}
                                    autoComplete='off'
                                    disabled={disableTGD}
                                /><Typography variant='p'>{`Remaining Asset : ${remainingTGDValue}`}</Typography> </Grid>))}
                        <Grid item xs={6}></Grid>
                        <Grid item xs={disableTGD ? 6 : 3}>
                            {disableTGD ? <Button variant='contained' onClick={() => setdisableTGD(false)}>Update</Button> :
                                <><Button variant='outlined' onClick={() => disableTGDFunc()}>Cancel</Button>
                                    &nbsp;<Button variant='contained' type='submit' disabled={remainingTGDValue<=100?false:true}>Save</Button></>}
                        </Grid>
                        <Grid item xs={6} sx={{ mt: 2 }}></Grid>
                    </Grid>
                </TabPanel>
            </Box>
        </>
    );
}