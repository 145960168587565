
import { Alert, Box, Button, Grid, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import { isMobile } from 'react-device-detect';
import { modeofPay, transactionType } from './Utils';
import axios from 'axios';
export default function CreateTransaction() {
  const [dob, setDOB] = useState(null);
  const [dropdowndatas, setDropdownValues] = useState({});
  const [userData, setUserData] = useState([])
  const [createError, setError] = useState(false);
  const [errorMessaage, setErrorMessage] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    const regdata = {
      data: {
        customer: data.get('customer'),
        transaction_date: data.get('transaction_date'),
        amount: data.get('amount'),
        type: data.get('type'),
        mode_of_pay: data.get('mode_of_pay'),
        note: data.get('note'),
      }
    }
    let token = localStorage.getItem('userToken')
    const confiq = {
      headers: {
        Authorization: `Bearer ${token} `
      }
    }
    axios.post(`${process.env.REACT_APP_API_URL}transactions`, regdata, confiq)
      .then(function (response) {
        setError(true)
          setErrorMessage('Transaction Completed')
          document.forms['createForm'].reset();
          setDropdownValues({})
      })
      .catch(function (error) {
        setError(true)
        setErrorMessage('Something Went Wrong')
      });

  };

  useEffect(() => {
    let token = localStorage.getItem('userToken')
    const confiq = {
      headers: {
        Authorization: `Bearer ${token} `
      }
    }
    axios.get(`${process.env.REACT_APP_API_URL}customers/user_details`, confiq)
      .then(function (response) {
        setUserData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const handleDropDownChange = (e) => {
    const key = e.target.name
    setDropdownValues({ ...dropdowndatas, [key]: e.target.value });
  }
  return (
    <Grid container component="main" sx={!isMobile ? { height: '100vh', pl: 50, pr: 50, pt: 10, pb: 10, width: '100%', } : { height: "100vh" }}>
         <Snackbar
        open={createError}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        message={errorMessaage}
      
      >
        <Alert
          onClose={() => setError(false)}
          severity={errorMessaage==='Transaction Completed'?'success':'error'}
          sx={{ width: '100%' }}
        >
          {errorMessaage}
        </Alert>
      </Snackbar>
      <Grid item xs={12} sm={12} md={12} lg={12} display='flex' justifyContent='center' component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 6,
            mx: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container component="form"    name='createForm' noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 1 }}><Typography variant='h5'>Create New Transaction</Typography></Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                select
                label='Customer Id'
                name='customer'
                id="customer"
                value={dropdowndatas.customer}
                onChange={(e) => handleDropDownChange(e)}
                fullWidth
              >
                {userData.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.id}

                  >
                    {name.email}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1, mt: 0 }}>

              <TextField
                margin="normal"
                required
                fullWidth
                name="transaction_date"
                label=""
                type="date"
                id="transaction_date"
                autoComplete='off'
                sx={{mt:0}}
              />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="amount"
                label="Amount"
                name="amount"
                autoComplete="off"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                select
                label='Transaction Type'
                name='type'
                id="type"
                value={dropdowndatas.type}
                onChange={(e) => handleDropDownChange(e)}
                fullWidth
                sx={{mt:2}}
              >
                {transactionType.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.value}

                  >
                    {name.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                select
                label='Mode of pay'
                name='mode_of_pay'
                id="mode_of_pay"
                value={dropdowndatas.mode_of_pay}
                onChange={(e) => handleDropDownChange(e)}
                fullWidth
              >
                {modeofPay.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.value}

                  >
                    {name.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <TextField
                margin="normal"

                fullWidth
                id="note"
                label="Any Notes"
                name="note"
                autoComplete="off"
                type="text"
                sx={{mt:0}}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <Button
                type="reset"
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => setDropdownValues({})}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create
              </Button>
            </Grid>

          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
